import {
  combineReducers,
  configureStore,
  PreloadedState
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import {
  createStateSyncMiddleware,
  initMessageListener,
  withReduxStateSync
} from "redux-state-sync";
import { Platform } from "react-native";

import StorageHelper from "../helpers/StorageHelper";
import {
  apiPartners,
  apiDevices,
  apiOrders,
  apiReadings,
  apiPAB,
  apiMemberConsents,
  apiMemberRegistration,
  apiRemoteIQ,
  apiTwilio,
  apiMemberDocuments,
  apiTasking,
  apiVideo,
  apiCalendar,
  apiVisits,
  apiAppointments,
  apiPanelManagement,
  apiMessaging
} from "../services/AxiosService";

import AlertSlice from "./AlertSlice";
import AuthSlice, {
  clearOldCredentials,
  finishRefreshLoading
} from "./AuthSlice";
import VideoCallSlice from "./VideoCallSlice";
import LayoutSlice from "./webadmin/LayoutSlice";
import AppointmentSlice from "./AppointmentSlice";
import StorageEnum from "../enums/StorageEnum";
import SettingsSlice from "./SettingsSlice";
import StartIntakeSlice from "./StartIntakeSlice";
import VisitsSlice from "./VisitsSlice";

const persistConfig = {
  key: "common_root",
  version: 1, // Increase this when changing the peristed reducers. (auth)
  storage: StorageHelper.getReduxStorage(),
  whitelist: ["auth", "settings", "visits"]
};

const stateSyncConfig = {
  whitelist: ["videoCall", "settings"],
  blacklist: ["auth", "persist/PERSIST", "persist/REHYDRATE"],
  predicate: (action) => {
    return action.type.includes("videoCall");
  },
  prepareState: (prevState, nextState) =>
    console.log("prepareState", prevState, nextState),
  receiveState: (prevState, nextState) =>
    console.log("receiveState", prevState, nextState)
};

export function initializeStore(preloadedState?: PreloadedState<any>) {
  const rootReducer = combineReducers({
    // Common Reducers
    alert: AlertSlice,
    auth: AuthSlice,
    videoCall: VideoCallSlice,
    settings: SettingsSlice,

    // RemoteIQ only reducers
    appointment: AppointmentSlice,
    startIntake: StartIntakeSlice,
    visits: VisitsSlice,

    // Redux Toolkit Query Reducers
    apiPAB: apiPAB.reducer,
    apiTasking: apiTasking.reducer,
    apiDevices: apiDevices.reducer,
    apiPartners: apiPartners.reducer,
    apiOrders: apiOrders.reducer,
    apiReadings: apiReadings.reducer,
    apiMemberRegistration: apiMemberRegistration.reducer,
    apiMemberConsents: apiMemberConsents.reducer,
    apiRemoteIQ: apiRemoteIQ.reducer,
    apiTwilio: apiTwilio.reducer,

    apiMemberDocuments: apiMemberDocuments.reducer,
    apiVideo: apiVideo.reducer,
    apiCalendar: apiCalendar.reducer,
    apiVisits: apiVisits.reducer,
    apiAppointments: apiAppointments.reducer,
    apiPanelManagement: apiPanelManagement.reducer,
    apiMessaging: apiMessaging.reducer,

    // Web only reducers. Could not detach them from common (type issues)
    layout: LayoutSlice
  });

  const peristedReducer = persistReducer(
    persistConfig,
    withReduxStateSync(rootReducer)
  );

  const store = configureStore({
    reducer: peristedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      const middlewares = [
        apiPAB.middleware,
        apiTasking.middleware,
        apiDevices.middleware,
        apiPartners.middleware,
        apiOrders.middleware,
        apiReadings.middleware,
        apiMemberRegistration.middleware,
        apiMemberConsents.middleware,
        apiRemoteIQ.middleware,
        apiMemberDocuments.middleware,
        apiVideo.middleware,
        apiCalendar.middleware,
        apiVisits.middleware,
        apiAppointments.middleware,
        apiPanelManagement.middleware,
        apiMessaging.middleware
      ];
      if (Platform.OS === "web")
        middlewares.push(createStateSyncMiddleware(stateSyncConfig));

      return getDefaultMiddleware({
        serializableCheck: false
      }).concat(middlewares as any);
    }
  });

  return store;
}

const store = initializeStore();

setupListeners(store.dispatch);
store.dispatch(finishRefreshLoading());

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const dispatch: AppDispatch = store.dispatch;

export const persistor = persistStore(store);

// TODO Remove: We need to migrate old credentials to new ones.
StorageHelper.getItem(StorageEnum.LOGIN_CREDENTIALS).then((result) => {
  // @ts-ignore
  const { auth } = store.getState();
  if (
    result === null &&
    auth.credentials !== null &&
    auth.credentials !== undefined
  ) {
    const access_token = auth.credentials.accessToken;
    const refresh_token = auth.credentials.refreshToken;
    const expires = auth.credentials.expires;

    StorageHelper.setItem(
      StorageEnum.LOGIN_CREDENTIALS,
      JSON.stringify({ access_token, refresh_token, expires })
    ).then(() => {
      dispatch(clearOldCredentials());
    });
  }
});
if (Platform.OS === "web") initMessageListener(store);
export { store };
