import { createSlice } from "@reduxjs/toolkit";

interface StateType {
  selectedSection: string;
  selectedSubSection: string;
  elapsedTime: { section_id: string; seconds: number }[];
  answers: { [id: string]: string | boolean };
}

const initialState: StateType = {
  selectedSection: null,
  selectedSubSection: null,
  elapsedTime: [],
  answers: {}
};

export const visitsSlice = createSlice({
  name: "visits",
  initialState,
  reducers: {
    setSelectedSection: (state, action) => {
      const { section_id, subsection_id } = action.payload;

      state.selectedSection = section_id;
      state.selectedSubSection = subsection_id;
    },
    addTime: (state, action) => {
      const { section_id, seconds } = action.payload;

      const currentSection = section_id ?? state.selectedSection;
      if (!currentSection) return;

      const sectionArray = [...state.elapsedTime];
      let sectionIndex = sectionArray.findIndex(
        (item) => item.section_id === currentSection
      );

      if (sectionIndex === -1) {
        sectionIndex = sectionArray.length;
        sectionArray.push({ section_id: currentSection, seconds: 0 });
      }

      sectionArray[sectionIndex].seconds += seconds;

      state.elapsedTime = sectionArray;
    },
    setAnswer: (state, action) => {
      const { id, value } = action.payload;
      const clonedAnswers = { ...state.answers };
      clonedAnswers[id] = value;
      state.answers = clonedAnswers;
    }
  }
});

export const { setSelectedSection, addTime, setAnswer } = visitsSlice.actions;

export default visitsSlice.reducer;
